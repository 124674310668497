@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  width: 100%;
  height: 100%;
  /* color: rgb(var(--foreground-rgb)); */
  background: #1e4734;
  color: #fff;
  overflow: overlay;
}
.calculator {
  max-width: 700px;
  max-height: 525px;
  width: 100%;
  height: 75vw;
  .dcg-calculator-api-container {
    .dcg-container {
      background: none !important;
    }
  }
}

::-webkit-scrollbar {
  overflow: overlay;
}

::-webkit-scrollbar-track {
  overflow: overlay;
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.5);
}

.flex-justify-between {
  justify-content: space-between;
}

.badges-mobile {
  margin-top: -4%;
  margin-right: -11%;
}

.vanta {
  min-height: 300px;
  color: #0c1857;
  padding: 20px;
  font-size: 24px;
}

.button-active {
  border-bottom: 3px solid #1c8819 !important;
  color: #157522 !important;
}

.button-link:hover {
  border-bottom: 3px solid #1c8819;
  color: #157522;
}

.button-link-mobile:hover {
  border-left: 3px solid #1c8819;
  color: #157522;
}

.text-heading {
  font-family: "Sora", sans-serif;
  font-weight: 600;
  color: #fff;
}

.text-normal {
  font-family: "Sora", sans-serif;
  font-weight: 400;
  color: #cac6dd;
}

.text-blue-1 {
  color: rgba(1, 167, 250, 1);
}

.text-grey-1 {
  color: rgba(255, 255, 255, 0.5);
}

.border-gradient {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ff98e2 0deg,
    #ffc876 16.88deg,
    #79fff7 142.5deg,
    #0182fa 258.75deg,
    #ff98e2 360deg
  );
}

.border-gradient-1 {
  border-color: linear-gradient(#33ceff, #d633ff);
}

.border-gray-1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.galaxy-bg {
  /* left: calc(50% - 1440px / 2) !important; */
  top: 213px !important;
}

.background-univer {
  background: linear-gradient(to top, black, transparent),
    linear-gradient(to top left, #15832d, transparent),
    linear-gradient(to top right, #6f1b72, transparent);
}

.background-gray {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
}

.background-card {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
}

.background-card:hover {
  background: rgba(14, 12, 21, 0.4);
}
.padding-card {
  padding: 56px 24px 32px;
}

.discover-padding {
  padding: 0px 0px 120px 0px;
}

.footer-border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.circle {
  width: 8px;
  height: 8px;
  background-color: #ddd;
  position: relative;
  border-radius: 20px;
  margin-right: 4px;
  overflow: hidden;
}

.mover {
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ff98e2 0deg,
    #ffc876 16.88deg,
    #79fff7 142.5deg,
    #0182fa 258.75deg,
    #ff98e2 360deg
  );
  transition: transform 0.5s ease;
}

.carousel-list {
  width: 99% !important;
}

/* .button-read:hover {
  color: ;
} */

.vanta-canvas {
  opacity: 0;
  -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s forwards; /* Firefox < 16 */
  -ms-animation: fadein 2s forwards; /* Internet Explorer */
  -o-animation: fadein 2s forwards; /* Opera < 12.1 */
  animation: fadein 2s forwards;
}

.available-on-items {
  overflow-x: hidden;
  @media (min-width: 350px) {
    padding: 1rem;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
